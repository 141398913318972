<template>
  <Head>
    <title>Спортивная инфраструктура {{ $titleEnd }}</title>
    <meta
      name="description"
      content="VERY станет вашим лучшим местом для занятий спортом — от зарядки в суперсовременном фитнес-зале до йоги в тиши векового леса. 2 500 кв. м спорта на территории - все возможности для спорта, активного отдыха и здорового образа жизни."
    />
    <meta
      property="og:title"
      :content="'Спортивная инфраструктура ' + $titleEnd"
    />
    <meta
      property="og:description"
      content="VERY станет вашим лучшим местом для занятий спортом — от зарядки в суперсовременном фитнес-зале до йоги в тиши векового леса. 2 500 кв. м спорта на территории - все возможности для спорта, активного отдыха и здорового образа жизни."
    />
    <meta
      property="og:image"
      content="https://api.gkosnova.tech/public/storage/media/2022/2/1200/GcaaogIqfHh8nj9abjg0ItrUnGJziLHHyMvopSpY.jpg"
    />
  </Head>
  <main class="main my-0">
    <Hero
      :content="hero"
      :breadcrumbs="[
        { name: 'Home', text: 'Главная' },
        { name: 'Advantages', text: 'Преимущества' },
        { text: hero.title },
      ]"
    />
    <Basis :content="basis" />
    <Image :content="image" />
    <Gallery :content="gallery" />
    <Green :content="green" />
    <Others />
    <HomeChoose />
  </main>
</template>

<script>
import Hero from "@/components/Hero.vue";
import Basis from "@/components/adv/inner/Basis.vue";
import Image from "@/components/adv/inner/Image.vue";
import Gallery from "@/components/adv/inner/Gallery.vue";
import Green from "@/components/adv/inner/Green.vue";
import Others from "@/components/adv/inner/Others.vue";
import HomeChoose from "@/components/home/Choose.vue";
import { Head } from "@vueuse/head";
export default {
  name: "Sport",
  components: {
    Head,
    Hero,
    Basis,
    Image,
    Gallery,
    Green,
    Others,
    HomeChoose,
  },
  data() {
    return {
      hero: {
        title: "Всегда быть в&nbsp;форме.<br> VERY легко!",
        text: "<p>VERY станет идеальным местом для вашей физической активности - от занятий на оборудованной современными тренажерами площадке до йоги в тиши векового леса.</p>",
        background: require("@i/html/sport/sport-hero.jpg"),
        video: require("@i/html/sport/sport.mp4"),
        more: "Узнайте больше",
      },

      basis: {
        h2: "Спортивная инфраструктура в&nbsp;ЖК&nbsp;Very",
        title:
          'Может&nbsp;ли спорт быть настолько <span class="text-masked">комфортным?</span>',
        subtitle: "2&nbsp;500&nbsp;кв.&nbsp;м&nbsp;спорта на&nbsp;территории",
        text: "<p>Все возможности для спорта, активного отдыха и&nbsp;здорового образа жизни&nbsp;&mdash; прямо на&nbsp;территории.</p>",
        list: [
          {
            title: "Активный спорт",
            text: "<p>Если тренировки и соревнования - часть вашей жизни, в VERY вас ждут уличный воркаут и командные игры с мячом.</p>",
            img: require("@i/html/sport/sport-m-1.jpg"),
          },
          {
            title: "В&nbsp;режиме лайт",
            text: "<p>Поддерживайте тонус, как вам больше нравится&nbsp;&mdash; аэробикой или йогой на&nbsp;свежем воздухе, велосипедными прогулками, голландской ходьбой, плаванием.</p>",
            img: require("@i/html/sport/sport-m-2.jpg"),
          },
          {
            title: "Релакс",
            text: "<p>Настройтесь на&nbsp;качественный отдых и&nbsp;восстановление. Под крышей спорткомплекса VERY&nbsp;&mdash; зона SPA, баня, сауна, несколько массажных кабинетов.</p>",
            img: require("@i/html/sport/sport-m-3.jpg"),
          },
        ],
      },

      image: {
        title: "Ваш собственный SPA-комплекс",
        text: "<p>Семейный SPA-комплекс Termoland - оазис спокойствия и уюта рядом с домом.</p><p>Откройте для себя невероятное сочетание фитнеса, комфорта, красоты природы и высокого уровня сервиса. Городской акватермальный курорт станет вашем любимым местом восполнения энергии, оздоровления, релакса и хорошего времяпровождения.</p><p>Здесь время замедляется, давая вам уникальную возможность насладиться каждой прожитой минутой.</p>",
        img: require("@i/html/home/feats-2.jpg"),
      },

      gallery: [
        {
          title: '<br class="d-none d-md-block">Волейбольная площадка',
          img: require("@i/html/sport/sport-1.jpg"),
          text: "Вмещает до&nbsp;12&nbsp;человек для активной командной игры. Волейбол&nbsp;&mdash; отличный способ познакомиться и&nbsp;подружиться с&nbsp;соседями.<br>Зимой волейбольная площадка превращается в каток.",
        },
        {
          title: '<br class="d-none d-md-block">Workout',
          img: require("@i/html/sport/sport-2.jpg"),
          text: "С&nbsp;комплексом для тренировки рук и&nbsp;корпуса, а&nbsp;также уличными тренажерами для различных типов тренировок в&nbsp;любом возрасте.",
        },
        {
          title: "Крытая площадка для мини-футбола",
          img: require("@i/html/sport/sport-3.jpg"),
          text: "Высокая интенсивность игры помогает быстро избавиться от&nbsp;лишнего веса. Игроки получают мяч на&nbsp;210% чаще, чем в&nbsp;большом футболе!",
        },
        {
          title: '<br class="d-none d-md-block">Спортивная площадка',
          img: require("@i/html/sport/sport-4.jpg"),
          text: "Тренируйтесь на&nbsp;свежем воздухе практически в&nbsp;любую погоду на&nbsp;оборудованной современными тренажёрами площадке.",
        },
      ],

      green: {
        title: "VERY много спорта вокруг",
        img: require("@i/html/sport/sport-green.jpg"),
        list: [
          {
            title: "Спорт в&nbsp;единении с&nbsp;природой",
            text: "<p>Находите свои пешеходные, беговые и&nbsp;веломаршруты&nbsp;&mdash; вокруг водоёмов, в&nbsp;тени деревьев. Природа словно поддерживает ваши стремления.</p>",
          },
          {
            title: "Территория ваших рекордов",
            text: "<p>Самый большой в&nbsp;Европе скейт-парк, самый большой в&nbsp;мире каток, крупнейший в&nbsp;России верёвочный парк. Всё это&nbsp;&mdash; рядом с&nbsp;домом.</p>",
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
.main {
  background: #f8f8f8;
}
</style>
